import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';

const BasicSlider = ({
  children,
  settings,
  showArrowButton = true,
  buttonClasses = '',
}) => {
  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  settings.arrows = false;

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      {showArrowButton && (
        <div
          className={`justify-end hidden sm:flex mt-[30px] ${buttonClasses}`}
        >
          <button className="btn-secondary btn mr-5" onClick={previous}>
            <BiChevronLeft className="text-[32px]" />
          </button>
          <button className="btn-secondary btn" onClick={next}>
            <BiChevronRight className="text-[32px]" />
          </button>
        </div>
      )}

      <style jsx>{`
        .btn.btn-secondary {
          width: 50px;
          height: 50px;
        }
      `}</style>

      <style jsx global>
        {`
          .slick-dots {
            bottom: -60px;

            @media all and (min-width: 768px) {
              width: 300px;
              left: 5px;
              text-align: left;
            }

            li button:before {
              font-size: 11px;
            }
            li.slick-active button:before {
              color: #18aa78;
            }
          }
        `}
      </style>
    </>
  );
};

BasicSlider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object.isRequired,
};

export default BasicSlider;
