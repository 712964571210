import React from 'react';

const CostItem = ({
  heading,
  title,
  content,
  needBottomMargin = true,
  arrowDirection = 'down',
}) => (
  <>
    <div
      className={`grid grid-cols-[105px_1fr] sm:grid-cols-[127px_1fr] gap-[24px] sm:gap-[40px] items-start sm:items-center ${
        needBottomMargin ? 'mb-[40px] lg:mb-[64px]' : ''
      }`}
    >
      <div className="font-bold flex items-center text-[24px] sm:text-[40px] min-w-[130px]">
        <img
          src={`/images/landing/index/cost/${
            arrowDirection === 'down' ? 'down_arrow' : 'up_arrow'
          }.svg`}
          alt="Down Arrow"
          className="mr-2"
        />
        <div>{heading}</div>
      </div>

      <div className="cost-item-content">
        <p className="compared-to text-[#2B2C2D] text-sm sm:text-[20px] mb-1.5 font-bold leading-[30px]">
          {title}
        </p>
        <p className="text-sm sm:text-base">{content}</p>
      </div>
    </div>
    <style jsx>
      {`
        @use 'sass:math';
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';

        .cost-item-wrapper {
          display: flex;
          align-items: flex-start;
          color: #fff;

          img {
            margin-top: 2px;
            padding: 8px;
          }

          .cost-item-content {
            padding-left: 16px;
            margin: 0 auto;

            h1 {
              @include font(32px, 700, -0.04em, 42px, #3c4550);
              margin-bottom: 8px;
            }

            p {
              @include font(16px, 400, -0.02em, 26px, #2b2c2d);
            }

            p.compared-to {
              color: rgba(43, 44, 45, 0.6);
              margin-bottom: 4px;
            }
          }
        }

        @media all and (min-width: 768px) {
          .cost-item-wrapper {
            img {
              margin-top: 10px;
              padding: 8px;
            }

            .cost-item-content {
              padding-left: 8px;

              h1 {
                font-size: 44px;
                line-height: 58px;
                margin-bottom: 8px;
              }

              p.compared-to {
                margin-bottom: 4px;
              }

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }

        @media all and (min-width: 1366px) {
          .cost-item-wrapper {
            padding-bottom: 4px;

            .cost-item-content {
              padding-left: 16px;

              h1 {
                margin-bottom: 8px;
              }

              p.compared-to {
                margin-bottom: 4px;
              }

              p {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }
      `}
    </style>
  </>
);

export default CostItem;
