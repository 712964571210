import React from 'react';

const VillaMarketTestimonial = () => (
  <div className="text-sm sm:text-base text-left sm:text-center lg:text-left">
    <img
      src="/images/customers/logo-villa_market@2x.png"
      alt="Villa market logo"
      width="220"
    />
    <p className="mb-3 mt-4">
      “Villa Market is using <strong>HLI (Human Logic Intelligence)</strong>{' '}
      primarily to <strong>reduce out of stock</strong> situation which is
      caused primarily by <strong>wrong ordering and forecasting.</strong>{' '}
      Currently our loss sales due to out of stock is running at around 10% of
      our sales. Of this number, what we can fix by simply using HLI is around
      70% as the other 30% is beyond our control. So that means HLI can help{' '}
      <strong>recover loss sales</strong> of $21M annually that means 25%
      increase to the bottomline.
    </p>
    <p>
      And this doesn’t include impacts on our{' '}
      <strong>customer experience</strong> since they can always get their
      products when they want. It is very hard to quantify but to a retailer, it
      is the number one prize because you can keep your customers coming back.
      It is a real edge!”
    </p>
    <div className="mt-8 text-sm text-[#2b2c2d]/[0.8]">
      Pisit Poosanakhom, Chairman, Villa Market
    </div>
  </div>
);

export default VillaMarketTestimonial;
