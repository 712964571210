import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import smoothscroll from 'smoothscroll-polyfill';

const GlobalLayout = ({ children, pageId, pageTitle, customCanonicalUrl }) => {
  const siteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
  const router = useRouter();
  const cleanPath = router.asPath.split('#')[0].split('?')[0];
  const canonicalUrl = `${siteUrl}${router.asPath === '/' ? '' : cleanPath}`;

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div
      className={`mind-body relative z-1 ${
        pageId ? `mind-body-${pageId}` : ''
      }`}
    >
      <Head>
        {pageTitle ? (
          <>
            <title>{pageTitle} | Mind Expression</title>
            <meta
              property="og:title"
              content={`${pageTitle} | Mind Expression`}
              key="ogtitle"
            />
            <meta
              property="twitter:title"
              content={`${pageTitle} | Mind Expression`}
              key="twittertitle"
            />
          </>
        ) : (
          <>
            <title>Mind Expression | AI Solutions for Businesses</title>
            <meta
              property="og:title"
              content="Mind Expression | AI Solutions for Businesses"
              key="ogtitle"
            />
            <meta
              property="twitter:title"
              content="Mind Expression | AI Solutions for Businesses"
              key="twittertitle"
            />
          </>
        )}
        <meta property="og:url" content="/" key="ogurl" />
        <link rel="canonical" href={customCanonicalUrl ?? canonicalUrl} />
      </Head>
      <div className="mind-content">{children}</div>
    </div>
  );
};

const Empty = () => <></>;

GlobalLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
  ]),
  pageId: PropTypes.string,
  pageTitle: PropTypes.string,
  customCanonicalUrl: PropTypes.string,
};

GlobalLayout.defaultProps = {
  children: Empty,
  pageId: '',
  pageTitle: '',
};

export default GlobalLayout;
