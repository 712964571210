import { useEffect } from 'react';

function useNavBarColorChange(dataRef) {
  const handleScroll = () => {
    const getOffsetTop = (el) =>
      window.pageYOffset + el.getBoundingClientRect().top;
    if (dataRef && dataRef.current) {
      const { current } = dataRef;
      const elTop = getOffsetTop(current);

      const header = window.document.querySelector('.mind-header');
      const navLinks = window.document.querySelectorAll('.nav-link');
      // if (header !== null && navLinks !== null) {
      //   if (elTop < 72) {
      //     header.classList.remove('mind-header-dark');
      //     Array.from(navLinks).forEach((el) => el.classList.remove('nav-dark'));
      //   } else {
      //     header.classList.add('mind-header-dark');
      //     Array.from(navLinks).forEach((el) => el.classList.add('nav-dark'));
      //   }
      // }
    }
  };

  useEffect(() => {
    window.addEventListener('load', handleScroll);
    return () => window.removeEventListener('load', handleScroll);
  }, []);

  useEffect(() => {
    const scrollView = document.querySelector('.me-body-scroll');
    scrollView?.addEventListener('scroll', handleScroll);
    return () => scrollView?.removeEventListener('scroll', handleScroll);
  }, []);
}

export default useNavBarColorChange;
